<template>
    <AuthLayout>
        <Button 
            color="primary"
            block
            @click.native="$router.push({ name: 'create-user' })"
            class="my-3"
        >
            Create New User
        </Button>
        <table class="table table-borderless hover" ref="usersTable">
            <thead>
                <tr>
                    <th class="vert-center text-center">Name</th>
                    <th class="vert-center text-center">Username</th>
                    <th class="vert-center text-center">Phone Number</th>
                    <th class="vert-center text-center">Email Address</th>
                    <th class="vert-center text-center">Lifebox Address</th>
                    <th class="vert-center text-center">Account No.</th>
                    <th class="text-center vert-center">Type</th>
                    <th class="text-center vert-center">Activated</th>
                    <th class="text-center vert-center">Signed Up</th>
                    <th class="text-center vert-center">Options</th>
                </tr>
            </thead>
        </table>
    </AuthLayout>
</template>

<script>
    import $ from 'jquery'
    import { mapGetters } from 'vuex'
    import swal from 'sweetalert'

    export default {
        name: 'Users',

        computed: {
            ...mapGetters('user', [
                'populatedUsers',
                'populatedUsersCount',
                'dataTableColumns',
                'impersonationLink'
            ]),
        },

        data: () => ({
            clickedPage: 0,
            dataTableElem: null,
            usersTable: null,
        }),

        methods: {
            initDataTable() {
                this.usersTable = this.dataTableElem.DataTable({
                    responsive: true,
                    serverSide: true,
                    processing: true,
                    language: {
                        info: "Displaying _START_ to _END_ of _TOTAL_ users",
                        infoFiltered: ""
                    },
                    ajax: async function (data, callback) {
                        await this.$store.dispatch('user/populateUsers', {
                            draw: data['draw'],
                            limit: data['length'],
                            search: data['search']['value'],
                            sort_by: this.dataTableColumns[data['order'][0]['column']],
                            sort_dir: data['order'][0]['dir'],
                            page: this.clickedPage
                        })

                        this.clickedPage = 0

                        callback({
                            draw: data.draw,
                            data: this.populatedUsers,
                            recordsFiltered: this.populatedUsersCount
                        })
                    }.bind(this),
                    columnDefs: [
                        {
                            className: 'align-middle text-center',
                            targets: 0
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 1
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 2
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 3
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 4
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 5
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 6
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 7,
                            render: data => {
                                if (data == 'active') return '<p class="text-center m-0 p-0"><i class="fa fa-check text-success"></i></p>'
                                return '<p class="text-center m-0 p-0"><i class="fa fa-times text-danger"></i></p>'
                            },
                            sortable: false
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 8
                        },
                        {
                            targets: 9,
                            render: (data, type, row, meta) => {
                                var editBtn = '<button id="edit_' + meta.row + '" class="btn btn-info btn-sm btn-block m-2 edit-button">' +
                                                    '<i class="fa fa-pencil mr-2"></i>' +
                                                    'Edit This User' +
                                                '</button>'

                                var removeBtn = '<button id="remove_' + meta.row + '" class="btn btn-danger btn-sm btn-block m-2 remove-button">' +
                                                    '<i class="fa fa-trash mr-2"></i>' +
                                                    'Remove This User' +
                                                '</button>'

                                return '<div class="my-auto pr-3">' +
                                            editBtn + 
                                            removeBtn + 
                                        '</div>'
                            },
                            sortable: false
                        }
                    ]   
                })

                this.autorefresh()
                this.checkClickedPage()
                this.onVueFunctions()
            },

            autorefresh() {
                setInterval(() => {
                    this.usersTable.ajax.reload(null, false)
                }, 300000)
            },

            checkClickedPage() {
                this.dataTableElem.on('page.dt', () => {
                    let info = this.usersTable.page.info()
                    this.clickedPage = ++info.page
                })
            },

            onVueFunctions() {
                const self = this

                $('tbody', this.$refs.usersTable).on('click', '.impersonate-button', function () {
                    var row = $(this).attr("id").match(/\d+/)[0]
                    var uid = self.usersTable.row(row).data()[9]
                    self.onImpersonateUser(uid)
                })

                $('tbody', this.$refs.usersTable).on('click', '.edit-button', function () {
                    var row = $(this).attr("id").match(/\d+/)[0]
                    var uid = self.usersTable.row(row).data()[9]
                    self.onEditUser(uid)
                })

                $('tbody', this.$refs.usersTable).on('click', '.remove-button', function () {
                    var row = $(this).attr("id").match(/\d+/)[0]
                    var uid = self.usersTable.row(row).data()[9]
                    self.onRemoveUser(uid)
                })
            },

            async onImpersonateUser(uid) {
                console.log('Impersonated User Is: ' + uid)
                await this.$store.dispatch('user/impersonateUser', { id: uid })
                window.location = this.impersonationLink
            },

            async onEditUser(uid) {
                this.$router.push({
                    name: 'update-user',
                    params: {
                        userID: uid 
                    } 
                })
            },

            async onRemoveUser(uid) {
                const message = {
					title: 'Are you sure you want to remove this user?',
					text: "The selected Lifebox user account will be deleted by the system. This action is irreversible.",
					icon: 'warning',
					buttons: true,
					dangerMode: true
				}

				if (await swal(message)) {
					await this.$store.dispatch('user/removeUser', uid)
                    this.usersTable.ajax.reload(null, false)
                    swal("User Deleted Successfully", "Users list will be updated now, Lifebox Admin.")
				}
            }
        },

        mounted() {
            this.dataTableElem = $(this.$refs.usersTable)
            this.initDataTable()
        },
    }
</script>

<style lang="scss" scoped>
    .vert-center {
        vertical-align: middle !important;
    }
</style>